import styled from '@emotion/styled';
import { Link } from '@remix-run/react';

export const SliderChildren = styled.div`
  height: 100%;
  scroll-snap-align: start;
  background: #000;
  position: relative;

  video {
    object-fit: cover;
  }
`;

export const FrameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Bold = styled.div`
  font-weight: 700;
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  
  display: none;
  
  &.active {
    display: block;
  }
`;

export const BackdropFull = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.80);
  z-index: 10;

  display: none;

  &.active {
    display: block;
  }
`;

export const LinkExt = styled(Link)`
  display: block;
  cursor: pointer;
  outline: none !important;
  text-decoration: none;
  color: inherit;

  &.active {
    color: #EB1717;
  }
`;
